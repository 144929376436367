<template>
  <TemplateBlock
    mod-class
    content-class="shop-contacts"
    title-page="Наши контакты"
  >
    <div class="w-100 h-100 shop-page">
      <ShopHeader />
      <div class="page__content container">
        Тут будут наши контакты или что-то с этим связанное)
      </div>
    </div>
  </TemplateBlock>
</template>

<script>
import TemplateBlock from "@/components/TepmplateBlock.vue";
import ShopHeader from "@/components/Shop/ShopHeader.vue";

export default {
  name: "ShopContacts",
  components: {
    TemplateBlock,
    ShopHeader
  }
};
</script>

<style lang="scss" scoped></style>
